/**
//==============================================================================
// Banner styles
//==============================================================================
*/

.banner {
  display: flex;
  position: relative;
  height: 94vh;
  width: 100%;
  border-bottom: 1px solid $black;
  padding-top: 20px;

  &__container {
    position: relative;
    width: 100%;
  }

  &__row-text {
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
    top: 8%;

    @include medium-breakpoint {
      top: 38%;
    }

    @include large-breakpoint {
      top: 38%;
      max-width: 800px;
    }

    @include x-large-breakpoint {
      top: 38%;
      max-width: 800px;
    }

    .main-text {
      line-height: $heading-line-height;
      z-index: 9;
      margin-right: 1rem;
      position: absolute;
      max-width: 10rem;
      font-size: $h1-mobile;

      @include medium-breakpoint {
        max-width: 50rem;
        font-size: $h1-mobile;
      }

      @include large-breakpoint {
        max-width: 50rem;
        font-size: $h1;
      }

      @include x-large-breakpoint {
        max-width: 50rem;
        font-size: $h1;
      }
    }
  }

  &__row-image {
    max-width: 100%;

    .main-image {
      display: block;
      position: absolute;
      max-width: 680px;
      width: 90vh;
      bottom: 0;
      left: 5%;

      @include medium-breakpoint {
        left: 20%;
        max-width: 790px;
      }

      @include large-breakpoint {
        left: 30%;
        max-width: 840px;
      }

      @include x-large-breakpoint {
        left: 45%;
        max-width: 960px;
      }
    }
  }
}
