.post-archive__grid {
  gap: $gap;
  columns: $columns-small;
  margin: 0 auto;
  display: column;

  @include medium-breakpoint {
    columns: $columns-medium;
  }

  @include large-breakpoint {
    columns: $columns-large;
  }
}

.post-archive__grid > * {
  break-inside: avoid;
  margin-bottom: $gap;

  img {
    max-width: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .flow > * + * {
    margin-top: $spacer;
  }
}

@supports (grid-template-rows: masonry) {
  .post-archive__grid {
    display: grid;
    grid-template-columns: repeat($columns-small, 1fr);
    grid-template-rows: masonry;
    grid-auto-flow: dense;
    /* align-tracks: stretch; */

    @include medium-breakpoint {
      grid-template-columns: repeat($columns-medium, 1fr);
    }

    @include large-breakpoint {
      grid-template-columns: repeat($columns-large, 1fr);
    }
  }

  .post-archive__grid > * {
    margin-bottom: 0em;
  }
}
