/**
//==============================================================================
// Global styling
//==============================================================================
*/

::-moz-selection {
  /* Code for Firefox */
  color: $black;
  background: $secondary-color;
}

::selection {
  color: $black;
  background: $secondary-color;
}

.link,
a {
  color: $black;
  text-decoration: underline;

  &:hover {
    color: $link-hover;
  }

  &:focus {
    color: $link-focus;
  }
}

span.title {
  &--underline-yellow {
    display: inline-block;
    position: relative;

    &:after {
      background: $secondary-color;
      content: "";
      position: absolute;
      bottom: -0.2em;
      left: -5%;
      transition: all 0.25s ease-in-out;
      width: 110%;
      height: 12px;
      z-index: -1;
    }

    &:hover:after {
      height: 30px;
    }
  }

  &--underline-blue {
    display: inline-block;
    position: relative;

    &:after {
      background: $primary-color;
      content: "";
      position: absolute;
      bottom: -0.6em;
      left: -5%;
      width: 110%;
      height: 12px;
      z-index: -1;
    }
  }
}

.contact {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0 1rem;
}

.img--responsive {
  width: 100vw;
  height: auto;
}

.note {
  &__scroll {
    font-size: 0.875rem;
    font-weight: $heading-font-weight;
    padding: 1.5rem;
    letter-spacing: 1.5px;
    position: absolute;
    bottom: 8%;
    left: 0;

    @include medium-breakpoint {
      bottom: 0;
    }
  }

  &__language {
    height: 25px;

    span {
      padding: 0 0.25rem;
      text-align: center;
    }
  }

  &__sidebar {
    font-size: 0.875rem;
    font-weight: $heading-font-weight;
    position: fixed;
    bottom: 8%;
    margin: 1.25rem;
    padding: 0.25rem;
    background-color: $background;
    letter-spacing: 1.5px;
    cursor: pointer;
    z-index: 600;

    @include medium-breakpoint {
      bottom: 0;
    }

    span {
      margin: -1rem;
      padding: 0.5rem;
      background-color: $background;
    }
  }

  &__swiper {
    display: inline-flex;
    font-size: 0.875rem;
    font-weight: $heading-font-weight;
    letter-spacing: 1.5px;
    cursor: pointer;

    span {
      padding: 0 0.25rem;
      text-align: center;
    }
  }
}
