/**
//==============================================================================
// Helpers
//==============================================================================
*/

// Classic clearfix
.clearfix::before,
.clearfix::after {
  content: " ";
  display: block;
}

.clearfix:after {
  clear: both;
}

// Responsive images
.responsive-image {
  max-width: 100%;
  height: auto;
}

// Display State
.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

// Spacing
.padding--0 {
  padding: 0;
}

.padding--top-0 {
  padding-top: 0;
}

.margin--0 {
  margin: 0;
}

.margin--top-0 {
  margin-top: 0;
}

.margin--2 {
  margin-bottom: 2rem 0;
}

.padding--2 {
  padding: 2rem 0;
}

.center {
  justify-content: center;
}
