/**
//==============================================================================
// Variables
//==============================================================================
*/

//==============================================================================
// Containers
//==============================================================================

$x-small: 480px;
$small: 720px;
$medium: 960px;
$large: 1200px;

//==============================================================================
// Breakpoints
//==============================================================================

$mobile: $x-small;
$tablet: $small;
$desktop: $medium;
$xl-desktop: $large;

//==============================================================================
// Global Colors
//==============================================================================

$background: #fff;
$white: #fff;
$black: #000;
$grey: #646464;
$primary-color: #001aff;
$secondary-color: #ffc700;
$link-hover: $grey;
$link-focus: $secondary-color;
$error: red;

//==============================================================================
// Typography
//==============================================================================

// Body
$font-size: 1.4rem;
$font-weight: 500;
$font-color: $black;
$font-family: "Inter", Arial, Helvetica, sans-serif;
$line-height: 1.6;

// Headings
$heading-font-weight: 700;
$heading-font-color: $black;
$heading-font-family: "Inter", Arial, Helvetica, sans-serif;
$heading-line-height: 1.5;

// Mobile heading font size
$h1-mobile: 2.5rem;
$h1-mobile-post-header: 6rem;
$h2-mobile: 2rem;
$h3-mobile: 1.75rem;
$h4-mobile: 1.5rem;
$h5-mobile: 1.25rem;

// Heading font size
$h1: 2.5rem;
$h1-post-header: 6rem;
$h2: 2rem;
$h3: 1.75rem;
$h4: 1.5rem;
$h5: 1.25rem;

//==============================================================================
// Spacing
//==============================================================================

$padding: 1rem;
$margins: 1.5rem;

//==============================================================================
// Borders
//==============================================================================

$border-width: 1px;
$border-style: solid;
$border-color: $grey;
$border-radius: 0;
$borders: $border-width $border-style $border-color;

//==============================================================================
// Post Archive
//==============================================================================

$spacer: 1rem;
$gap: 1em;
$columns-small: 1;
$columns-medium: 2;
$columns-large: 3;
